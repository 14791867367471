import { Button, Paper, Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';

type Props = {
  open: boolean;
  closeDialog: () => void;
  onConfirm: () => void;
};

export default function DeleteConfirmation({
  open,
  closeDialog,
  onConfirm,
}: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={closeDialog}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('adminDashboard.deleteTeamMember.title'))}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {String(translate('adminDashboard.deleteTeamMember.description'))}
        </Typography>
        <Stack
          direction={{ xs: 'column' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            onClick={onConfirm}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('adminDashboard.deleteTeamMember.onConfirm'))}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={closeDialog}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('adminDashboard.deleteTeamMember.onClose'))}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
