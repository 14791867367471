import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from '#/components/shared/hook-form';
import MemberInput from '#/components/pages/User/billing/family-pack/MemberInput';
import useLocales from '#/hooks/useLocales';
import {
  invalidateSuccessorQueries,
  useCreateInvitation,
  useGetInvitationsList,
} from '#/api/successor';
import Iconify from '#/components/shared/ui/Iconify';
import useAuth from '#/hooks/useAuth';
import InputSkeletons from '#/components/pages/User/billing/family-pack/InputSkeletons';

export interface FormValues {
  invitations?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    type?: string;
  }[];
  agree?: boolean;
}
export default function Members() {
  const { translate } = useLocales();
  const { user } = useAuth();

  const [loadingEntireList, setLoadingEntireList] = useState<boolean>(true);

  const { mutateAsync: createInvitation, isLoading } = useCreateInvitation();
  const { data: list, isLoading: loadingList } =
    useGetInvitationsList('FAMILY');

  const InvitationSchema = Yup.object().shape({
    invitations: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string()
            .optional
            // 'validations.successors.first_name'
            (),
          last_name: Yup.string()
            .optional
            // 'validations.successors.last_name'
            (),
          email: Yup.string().email('validations.successors.invalidEmail'),
        })
      )
      .min(2, 'validations.successors.min'),
  });

  const defaultValues = useMemo(
    () => ({
      invitations: list,
    }),
    [list]
  );

  const methods = useForm<FormValues>({
    resolver: yupResolver(InvitationSchema),
    defaultValues,
  });

  const { handleSubmit, reset, control } = methods;

  const { fields, append } = useFieldArray({
    control,
    name: 'invitations',
  });

  const onSubmit = async (data: any) => {
    if (data.invitations[0].email === user?.email) {
      data.invitations.shift();
    }

    const requestData = {
      invitations: data.invitations.filter(
        (item: any) => item.first_name && item.last_name && item.email
      ),
    };

    try {
      await createInvitation(requestData).then((r) => {
        if (r?.data?.errors[0]) {
          toast.error(r?.data?.errors[0]?.error);
        } else {
          toast.success(
            String(translate('toast_notifications.success.invitation_sent'))
          );
        }
        invalidateSuccessorQueries.getInvitationsList();
      });
    } catch (e) {
      toast.error('Error');
    }
  };

  useEffect(() => {
    if (list) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (user?.family_members && user?.family_members > fields.length) {
      for (let i = fields.length; i < user?.family_members; i += 1) {
        append({ first_name: '', last_name: '', email: '', type: 'FAMILY' });
      }
    }
    if (user?.family_members === fields.length) {
      setLoadingEntireList(false);
    }
    // eslint-disable-next-line
  }, [user, fields]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {loadingList &&
        Array.from({ length: 6 }, (_, i) => <InputSkeletons key={i} />)}
      {loadingEntireList &&
        Array.from({ length: 6 }, (_, i) => <InputSkeletons key={i} />)}
      {!loadingList && !loadingEntireList && (
        <>
          {fields.map((item, index) => (
            <MemberInput index={index} key={item.id} />
          ))}
        </>
      )}
      <Stack
        direction="row"
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: 'auto' }}
          disabled={isLoading}
          startIcon={
            isLoading ? (
              <Iconify icon="line-md:loading-twotone-loop" />
            ) : (
              <Iconify icon="mdi:content-save" />
            )
          }
        >
          <Typography>{String(translate('global.save'))}</Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
}
