const sortingModel = [
  {
    field: 'name',
    sort_by: {
      asc: 'first_name',
      desc: '-first_name',
    },
  },
  {
    field: 'email',
    sort_by: {
      asc: 'email',
      desc: '-email',
    },
  },
  {
    field: 'role',
    sort_by: {
      asc: 'role',
      desc: '-role',
    },
  },
  {
    field: 'last_login',
    sort_by: {
      asc: 'last_login',
      desc: '-last_login',
    },
  },
];

export default sortingModel;
