const roles = [
  {
    value: 'Owner',
    label: 'Owner',
  },
  {
    value: 'Consultant',
    label: 'Consultant',
  },
  {
    value: 'Admin',
    label: 'Admin',
  },
];

export default roles;
