import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import Iconify from '#/components/shared/ui/Iconify';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useBoolean from '#/hooks/useBoolean';
import usePopover from '#/hooks/usePopover';
import useLocales from '#/hooks/useLocales';
import { ITeam } from '#/types/partner';
import { fDate } from '#/utils/formatTime';
import useAuth from '#/hooks/useAuth';
import DeleteConfirmation from '#/components/pages/Admin/team-table/ConfirmationModals/DeleteConfirmation';
import {
  invalidatePartnerQueries,
  useDeleteTeamMember,
} from '#/api/partnerQueries';
import CancelInvitationConfirmation from '#/components/pages/Admin/team-table/ConfirmationModals/CancelInvitationConfirmation';

type Props = {
  selected: boolean;
  row: ITeam | any;
  // onSelectRow: VoidFunction;
  onCancelInvitation: VoidFunction;
  onResendInvitation: VoidFunction;
};

export default function MemberTableRow({
  row,
  selected,
  // onSelectRow,
  onCancelInvitation,
  onResendInvitation,
}: Props) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [cancelInvitationConfirmation, setCancelInvitationConfirmation] =
    useState(false);

  const handleOpenDeleteConfirmation = () => setOpenDeleteConfirmation(true);
  const handleCloseDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const handleOpenCancelInvitationConfirmation = () =>
    setCancelInvitationConfirmation(true);
  const handleCloseCancelInvitationConfirmation = () =>
    setCancelInvitationConfirmation(false);

  const {
    id,
    first_name,
    last_name,
    avatarUrl,
    email,
    last_login,
    role,
    invited_by,
  } = row;

  const { translate } = useLocales();
  const { user } = useAuth();
  const { mutateAsync: deleteRow } = useDeleteTeamMember();

  const lowerCaseRole = String(
    translate(`global.teamRoles.${role.toLowerCase()}`)
  );

  const name = `${first_name} ${last_name}`;

  const noName = `${first_name} ${last_name}` === ' ';

  const confirm = useBoolean();

  // const quickEdit = useBoolean();

  const popover = usePopover();

  const onDeleteRow = () => {
    confirm.onFalse();
    deleteRow({ id })
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.delete_team_member'))
        );
        invalidatePartnerQueries.teamList();
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.delete_team_member'))
        );
      });
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={noName ? 'N/A' : name}
            // secondary={email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
          {lowerCaseRole}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {last_login ? fDate(last_login, 'dd. MMM yyyy', 'de') : 'N/A'}
        </TableCell>

        {email !== user?.email && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {email !== user?.email && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 'auto' }}
        >
          {invited_by && (
            <MenuItem
              onClick={() => {
                onResendInvitation();
                confirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'text.primary' }}
            >
              <Iconify
                icon="fluent:send-16-filled"
                color={(theme) => theme.palette.primary.main}
              />
              {String(translate('global.team.resendInvitation'))}
            </MenuItem>
          )}

          {invited_by && (
            <MenuItem
              onClick={() => {
                handleOpenCancelInvitationConfirmation();
                popover.onClose();
              }}
            >
              <Iconify
                icon="material-symbols:cancel-outline"
                color={(theme) => theme.palette.error.main}
              />
              {String(translate('global.team.cancelInvitation'))}
            </MenuItem>
          )}
          {!invited_by && (
            <MenuItem
              onClick={() => {
                handleOpenDeleteConfirmation();
                popover.onClose();
              }}
            >
              <Iconify
                icon="material-symbols:delete"
                color={(theme) => theme.palette.error.main}
              />
              {String(translate('global.team.cancelInvitation'))}
            </MenuItem>
          )}
        </CustomPopover>
      )}
      <DeleteConfirmation
        open={openDeleteConfirmation}
        closeDialog={handleCloseDeleteConfirmation}
        onConfirm={onDeleteRow}
      />
      <CancelInvitationConfirmation
        open={cancelInvitationConfirmation}
        closeDialog={handleCloseCancelInvitationConfirmation}
        onConfirm={onCancelInvitation}
      />
    </>
  );
}
