import React, { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import Page from '#/components/shared/ui/Page';
import Iconify from '#/components/shared/ui/Iconify';
import AccountGeneral from '../../components/pages/Admin/AccountGeneral';
import AccountChangePassword from '../../components/pages/User/AccountChangePassword';
import useLocales from '#/hooks/useLocales';
import Team from '#/components/pages/Admin/Team';
import Consulting from '#/components/pages/Admin/Consulting';

const AdminAccountPage = () => {
  const { translate } = useLocales();

  const [currentTab, setCurrentTab] = useState(
    // eslint-disable-next-line no-nested-ternary
    'general'
  );

  const TABS = [
    {
      value: 'general',
      label: String(translate('global.adminSettings.general')),
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },
    {
      value: 'team',
      label: String(translate('global.adminSettings.team')),
      icon: <Iconify icon="fluent:people-team-48-filled" />,
      component: <Team />,
    },
    {
      value: 'consulting',
      label: String(translate('global.adminSettings.consulting')),
      icon: <Iconify icon="mdi:monitor" />,
      component: <Consulting />,
    },
    {
      value: 'change_password',
      label: String(translate('global.adminSettings.changePassword')),
      icon: <Iconify icon="ic:round-vpn-key" />,
      component: <AccountChangePassword />,
    },
  ];

  return (
    <Page title={`${TABS.find((tab) => tab.value === currentTab)?.label} `}>
      <Container maxWidth="lg">
        <Tabs
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Page>
  );
};

export default AdminAccountPage;
