import { Button, Paper, Typography } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/lib/hooks/useLocales';
import DataSharingForm from './data-sharing-form';

type DataSharingSettingProps = {
  open: boolean;
  closeModal: () => void;
};

export default function DataSharingSetting({
  open,
  closeModal,
}: DataSharingSettingProps) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={closeModal} maxWidth="md">
      <Paper
        sx={{
          padding: 2,
          '& > *': {
            marginBottom: '1rem',
          },
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('global.editAccessRights'))}
        </Typography>
        <Typography gutterBottom color="text.secondary">
          {String(translate('global.editAccessRightsDscp'))}
        </Typography>
        <DataSharingForm />
        <Button variant="contained" onClick={closeModal}>
          <Typography>{String(translate('global.done'))}</Typography>
        </Button>
      </Paper>
    </DialogAnimate>
  );
}
