import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { RHFTextField } from '#/components/shared/hook-form';
import useAuth from '#/hooks/useAuth';
import { useGetInvitationsList } from '#/api/successor';
import { Successor } from '#/types/user';

type SuccessorInputProps = {
  index: number;
};
export default function MemberInput({ index }: SuccessorInputProps) {
  const { user } = useAuth();
  const { translate } = useLocales();
  const { watch } = useFormContext();

  const { data: list } = useGetInvitationsList('FAMILY');

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ marginBottom: '2em' }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: 544,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <RHFTextField
            name={`invitations[${index}].first_name`}
            label={String(translate('global.formLabels.firstName'))}
            disabled={
              list?.some(
                (invitation: Successor) =>
                  invitation?.first_name ===
                  watch(`invitations[${index}].first_name`)
              ) ||
              user?.first_name === watch(`invitations[${index}].first_name`)
            }
          />
          <RHFTextField
            name={`invitations[${index}].last_name`}
            label={String(translate('global.formLabels.lastName'))}
            disabled={
              list?.some(
                (invitation: Successor) =>
                  invitation?.last_name ===
                  watch(`invitations[${index}].last_name`)
              ) || user?.last_name === watch(`invitations[${index}].last_name`)
            }
          />
        </Stack>
      </Box>

      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={1}
        sx={{
          width: {
            xs: '100%',
            sm: 544,
          },
        }}
      >
        <RHFTextField
          name={`invitations[${index}].email`}
          label="Email"
          disabled={
            list?.some(
              (invitation: Successor) =>
                invitation?.email === watch(`invitations[${index}].email`)
            ) || user?.email === watch(`invitations[${index}].email`)
          }
        />
      </Stack>
    </Stack>
  );
}
