import { useForm } from 'react-hook-form';
import { Card, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import useAuth from '../../../lib/hooks/useAuth';
import {
  FormProvider,
  RHFDatePicker,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from '#/components/shared/hook-form';
import { IUserAccountGeneral } from '#/types/user';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import { useUpdateProfile } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';
import PhoneInput from '#/components/shared/hook-form/PhoneInput';
import AccountPicture from '#/components/pages/User/AccountPicture';
import WarningStatusModal from '#/components/pages/User/WarningStatusModal';
import { invalidateServicesStatsQueries } from '#/api/servicesQueries';
import useCheckCivilStatus from '#/hooks/useCheckCivilStatusChange';
import { fToDB } from '#/utils/formatTime';

type FormValuesProps = IUserAccountGeneral;

const genders = [
  {
    value: 'M',
    label: 'male',
  },
  {
    value: 'F',
    label: 'female',
  },
];

const civilStatus = [
  {
    code: 'single',
  },
  {
    code: 'married',
  },
  {
    code: 'divorced',
  },
  {
    code: 'widowed',
  },
  {
    code: 'registered_partnership',
  },
];

const countries = [{ code: 'CH', label: 'global.switzerland' }];
export default function AccountGeneral() {
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleOpenWarning = () => setOpenWarning(true);
  const handleCloseWarning = () => setOpenWarning(false);

  const { user, refetch } = useAuth();

  const { translate } = useLocales();

  const { mutateAsync: updateProfile, isLoading } = useUpdateProfile();

  const defaultValues = {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    mobile_phone: user?.mobile_phone || '',
    address: {
      country: user?.address?.country || 'CH',
      street: user?.address?.street || '',
      city: user?.address?.city || '',
      zip_code: user?.address?.zip_code || '',
      house_number: user?.address?.house_number || '',
    },
    gender: user?.gender || '',
    civil_status: user?.civil_status || '',
    date_of_birth: user?.date_of_birth || null,
    two_factor_auth: user?.two_factor_auth || false,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = methods;

  const hasSelectedMarried = watch('civil_status') === 'married';

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setValue('mobile_phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const disabled = !isDirty || isSubmitting;

  const onSubmit = async (data: FormValuesProps) => {
    if (JSON.stringify(data) === JSON.stringify(defaultValues)) {
      toast.loading('Nothing to update here...', {
        duration: 1500,
        icon: '🤔',
      });

      return;
    }

    const reqData: FormValuesProps = {
      ...data,
      date_of_birth: fToDB(data.date_of_birth),
    };

    if (!reqData.date_of_birth) {
      delete reqData.date_of_birth;
    }

    await updateProfile(reqData)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.profile_update'))
        );
      })
      .then(() => {
        refetch();
        invalidateServicesStatsQueries.getServicesStats();
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.profile_update'))
        );
      });
  };

  const fieldNames = {
    'address.street': 'route',
    'address.house_number': 'street_number',
    'address.zip_code': 'postal_code',
    'address.city': 'locality',
  };

  const isStreetError = Boolean(errors?.address?.street);

  useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [user]);

  useCheckCivilStatus({
    user,
    watch,
    handleOpenWarning,
  });

  const handleChangeAnswer = () => {
    setValue('civil_status', user?.civil_status);
    handleCloseWarning();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
          <AccountPicture />
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
              >
                <RHFTextField
                  name="first_name"
                  label={String(translate('global.formLabels.firstName'))}
                />
                <RHFTextField
                  name="last_name"
                  label={String(translate('global.formLabels.lastName'))}
                />
                <RHFSelect
                  name="gender"
                  label={String(translate('global.gender'))}
                >
                  {genders.map((g, i) => (
                    <MenuItem key={i} value={g.value}>
                      {String(translate(`global.${g.label}`))}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
              >
                <Stack
                  width={{
                    xs: '100%',
                  }}
                >
                  <RHFDatePicker
                    name="date_of_birth"
                    label={String(translate('global.formLabels.date_of_birth'))}
                  />
                </Stack>
                <Stack
                  width={{
                    xs: '100%',
                  }}
                >
                  <RHFSelect
                    name="civil_status"
                    label={String(translate('global.formLabels.civilStatus'))}
                  >
                    {civilStatus.map((status) => (
                      <MenuItem
                        key={status.code}
                        value={status.code}
                        disabled={
                          hasSelectedMarried && status.code === 'single'
                        }
                      >
                        {String(translate(`global.civilStatus.${status.code}`))}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Stack>
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
              >
                <RHFTextField
                  name="email"
                  label={String(translate('global.formLabels.emailAddress'))}
                  disabled
                />
                <PhoneInput
                  name="mobile_phone"
                  value={watch('mobile_phone')}
                  label={String(translate('global.formLabels.mobilePhone'))}
                  onChange={(e) => handlePhoneChange(e)}
                  sx={{ width: '100%' }}
                />
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
              >
                <Stack sx={{ width: '100%' }}>
                  <AutoAddressPicker
                    fieldNames={fieldNames}
                    streetError={isStreetError}
                    name="address.street"
                  />
                </Stack>
                <RHFTextField
                  name="address.house_number"
                  label={String(translate('global.formLabels.houseNumber'))}
                />
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={1}
              >
                <RHFTextField
                  name="address.zip_code"
                  label={String(translate('global.formLabels.postalCode'))}
                />
                <RHFTextField
                  name="address.city"
                  label={String(translate('global.formLabels.city'))}
                />
              </Stack>
              <RHFSelect
                name="address.country"
                label={String(translate('global.formLabels.country'))}
                value="CH"
                defaultValue={
                  countries.find((country) => country.code === 'CH')?.code
                }
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {String(translate(country.label))}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFSwitch
                name="two_factor_auth"
                label={String(translate('global.formLabels.twoFactorAuth'))}
                helperText={String(
                  translate('global.formLabels.twoFactorAuthHelp')
                )}
              />
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  aria-label="save changes"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || isLoading}
                  disabled={disabled}
                >
                  <Typography>{String(translate('global.save'))}</Typography>
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
      <WarningStatusModal
        open={openWarning}
        closeDialog={handleCloseWarning}
        changeAnswer={handleChangeAnswer}
      />
    </Grid>
  );
}
