import { Stack, Skeleton } from '@mui/material';

export default function InputSkeletons() {
  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      alignItems="center"
      spacing={1}
      sx={{
        my: 2,
      }}
    >
      <Skeleton variant="rounded" width="100%" height={56} animation="pulse" />
      <Skeleton variant="rounded" width="100%" height={56} animation="pulse" />
      <Skeleton variant="rounded" width="100%" height={56} animation="pulse" />
    </Stack>
  );
}
