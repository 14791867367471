import { Stack } from '@mui/material';
import AccountPlatform from '#/components/pages/Admin/AccountPlatform';
import AccountUser from '#/components/pages/Admin/AccountUser';

export default function AccountGeneral() {
  return (
    <Stack
      sx={{
        rowGap: 5,
      }}
    >
      <AccountPlatform />
      <AccountUser />
    </Stack>
  );
}
