import { Chip, MenuItem, Typography } from '@mui/material';
import RHFAutocompleteTwo from '#/components/shared/hook-form/RHFAutocomplete2';
import useLocales from '#/lib/hooks/useLocales';
import { SERVICE_OPTIONS } from '../successors/schema-validation';

export default function DataSharingForm() {
  const { translate } = useLocales();

  const getServiceLabel = (value: string) => {
    const service = SERVICE_OPTIONS.find((option) => option.value === value);
    return service ? String(translate(service.label)) : value;
  };

  return (
    <RHFAutocompleteTwo
      name="specific_service_access"
      label={String(translate('global.servicesToShare'))}
      placeholder={String(translate('global.servicesToShare'))}
      multiple
      freeSolo
      options={SERVICE_OPTIONS.map((option) => option.value)}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option}>
          <Typography sx={{ textTransform: 'none' }}>
            {getServiceLabel(option)}
          </Typography>
        </MenuItem>
      )}
      renderTags={(selected, getTagProps) =>
        selected.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option}
            label={getServiceLabel(option)}
            size="small"
            color="primary"
            variant="filled"
          />
        ))
      }
    />
  );
}
