import useAuth from '#/hooks/useAuth';
import AdminAccountPage from '#/pages/dashboard/AdminAccountPage';
import UserAccountPage from '#/pages/dashboard/UserAccountPage';

export default function ProfileSettings() {
  const { user } = useAuth();

  const partnerRole = user?.partner?.role;

  if (partnerRole) {
    if (partnerRole === 'admin' || partnerRole === 'owner') {
      return <AdminAccountPage />;
    }
    return <UserAccountPage />;
  }
  return <UserAccountPage />;
}
