import { Box } from '@mui/material';
import ConsultingLegal from '#/components/pages/Admin/ConsultingLegal';
import ConsultingFinancial from '#/components/pages/Admin/ConsultingFinancial';

export default function Consulting() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <ConsultingLegal />
      <ConsultingFinancial />
    </Box>
  );
}
