import { Card, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import Members from '#/components/pages/User/billing/family-pack/Members';

export default function FamilyPack() {
  const { translate } = useLocales();

  return (
    <Card
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="subtitle1">
        {String(translate('global.familyPackage.title'))}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {String(translate('global.familyPackage.description'))}
      </Typography>
      <Typography color="primary">
        {String(translate('global.familyPackage.helperText'))}
      </Typography>
      <Members />
    </Card>
  );
}
