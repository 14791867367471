import { Card, Tooltip, Typography } from '@mui/material';
import React from 'react';
import useLocales from '#/hooks/useLocales';
import SuccessorForm from '#/components/pages/User/successors/successor-form';

type Props = {
  closeModal?: () => void;
};
export default function AccountSuccessors({ closeModal }: Props) {
  const { translate } = useLocales();

  return (
    <Card
      sx={{ p: 3, display: 'flex', flexDirection: 'column', rowGap: '30px' }}
    >
      <Typography variant="subtitle1">
        {String(translate('global.userSettings.successor.title'))}
      </Typography>
      <Typography variant="subtitle1" fontWeight="normal">
        {String(translate('global.userSettings.successor.description'))}
      </Typography>
      <Typography variant="body2">
        {highlightWords(
          String(translate('global.userSettings.successor.pleaseConfirm')),
          [String(translate('guest.seeWhatsIncluded'))],
          String(translate('guest.includedTooltip'))
        )}
      </Typography>
      <SuccessorForm closeModal={closeModal} />
    </Card>
  );
}

function highlightWords(
  sentence: string,
  targetPhrases: string[],
  tooltip?: string
) {
  let elements: React.ReactNode[] = [sentence];
  targetPhrases.forEach((phrase, i) => {
    elements = elements.flatMap((el, j) => {
      if (typeof el === 'string') {
        const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
        return parts.map((part, k) =>
          part.toLowerCase() === phrase.toLowerCase() ? (
            <Tooltip key={`${i}-${j}-${k}`} title={tooltip}>
              <span style={{ color: '#3366FF', cursor: 'pointer' }}>
                {part}
              </span>
            </Tooltip>
          ) : (
            part
          )
        );
      }
      return el;
    });
  });
  return elements;
}
