import {
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-hot-toast';
import useTable from '#/hooks/useTable';
import TeamTableToolbar from '#/components/pages/Admin/team-table/team-table-toolbar';
import TeamTableFilterResult from '#/components/pages/Admin/team-table/team-table-filter-result';
import TableSelectedAction from '#/components/shared/table/table-selected-action';
import Iconify from '#/components/shared/ui/Iconify';
import useBoolean from '#/hooks/useBoolean';
import Scrollbar from '#/components/shared/Scrollbar';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import { emptyRows } from '#/components/shared/table/utils';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import MemberTableRow from '#/components/pages/Admin/team-table/member-table-row';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import TablePaginationCustom from '#/components/shared/table/table-pagination-custom';
import InviteMember from '#/components/pages/Admin/team-table/InviteMember/InviteMember';
import {
  invalidatePartnerQueries,
  useCancelInvitation,
  useGetTeamList,
  useResendInvitation,
} from '#/api/partnerQueries';
import roles from '#/components/pages/Admin/team-table/roles';
import useDebounce from '#/hooks/useDebounce';
import useLocales from '#/hooks/useLocales';
import sortingModel from '#/components/pages/Admin/team-table/sortingModel';

const defaultFilters = {
  first_name: '',
  last_name: '',
  role: [],
  search: '',
  last_login: 'all',
};

const TABLE_HEAD = [
  { id: 'name', label: 'adminDashboard.teamTable.tableHeads.name' },
  {
    id: 'email',
    label: 'adminDashboard.teamTable.tableHeads.email',
    width: 280,
  },
  { id: 'role', label: 'adminDashboard.teamTable.tableHeads.role', width: 180 },
  {
    id: 'last_login',
    label: 'adminDashboard.teamTable.tableHeads.last_login',
    width: 180,
  },
  { id: '', width: 88 },
];

export const rolesOptions = roles.map((role) => role.value);

export default function Team() {
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedSearch = useDebounce(filters.search, 500);
  const table = useTable();
  const { translate } = useLocales();

  const hasFilters = filters?.role.length > 0 || debouncedSearch;

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const { data: teamList } = useGetTeamList(
    hasFilters ? 1 : table.page + 1,
    true,
    filters?.role.length > 0
      ? // @ts-ignore
        filters?.role.map((element) => element.toLowerCase()).join(',')
      : undefined,
    debouncedSearch || undefined,
    table.order ? handleSorting() : undefined
  );

  const { mutateAsync: cancelInvitation } = useCancelInvitation();
  const { mutateAsync: resendInvitation } = useResendInvitation();

  const confirm = useBoolean();

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const notFound =
    (!teamList?.results?.length && canReset) || !teamList?.results?.length;

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleCancelInvitation = async (id: string | number) => {
    try {
      await cancelInvitation({ id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.cancel_invitation'))
        );
        invalidatePartnerQueries.teamList();
      });
    } catch (error) {
      console.error(error);
      toast.error(
        String(translate('toast_notifications.error.cancel_invitation'))
      );
    }
  };

  const handleResendInvitation = async (invitation_id: string | number) => {
    try {
      await resendInvitation({ invitation_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.resend_invitation'))
        );
        invalidatePartnerQueries.teamList();
      });
    } catch (error) {
      console.error(error);
      toast.error(
        String(translate('toast_notifications.error.resend_invitation'))
      );
    }
  };

  return (
    <Container maxWidth="lg">
      <Card>
        <InviteMember />
        <TeamTableToolbar
          filters={filters}
          onFilters={handleFilters}
          roleOptions={rolesOptions}
        />
        {canReset && (
          <TeamTableFilterResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={teamList?.count || 0}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={teamList?.results?.length || 0}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                teamList?.results?.map((row) => String(row.id)) || []
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />
          <Scrollbar>
            <Table
              size={table.dense ? 'small' : 'medium'}
              sx={{ minWidth: 960 }}
            >
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                onSort={table.onSort}
              />

              <TableBody>
                {teamList?.results?.map((row) => (
                  <MemberTableRow
                    key={row.id}
                    row={row}
                    selected={table.selected.includes(String(row.id))}
                    onCancelInvitation={() => handleCancelInvitation(row.id)}
                    onResendInvitation={() => handleResendInvitation(row.id)}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    teamList?.count || 0
                  )}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={teamList?.count || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}
