import { Paper, Typography } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import SpecificServiceAccessFieldArray from '#/components/pages/User/successors/specific-service-access-field-array';
import useLocales from '#/hooks/useLocales';

type Props = {
  nestIndex: number;
  open: boolean;
  closeModal: () => void;
};

export default function AdvancedAccessModal({
  open,
  closeModal,
  nestIndex,
}: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={closeModal} maxWidth="md">
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(
            translate('global.userSettings.successor.access.modal.title')
          )}
        </Typography>
        <SpecificServiceAccessFieldArray
          nestIndex={nestIndex}
          closeModal={closeModal}
        />
      </Paper>
    </DialogAnimate>
  );
}
