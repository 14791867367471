import React, { useCallback } from 'react';
import {
  FormControl,
  SelectChangeEvent,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  Stack,
  Select,
  TextField,
  InputAdornment,
} from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = {
  filters: {
    first_name: string;
    last_name: string;
    role: string[];
    search: string;
    last_login: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  roleOptions: string[];
};

export default function TeamTableToolbar({
  filters,
  onFilters,
  roleOptions,
}: Props) {
  const { translate } = useLocales();

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search', event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'role',
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel>
          {String(translate('global.team.tableHeads.role'))}
        </InputLabel>

        <Select
          multiple
          value={filters.role}
          onChange={handleFilterRole}
          input={<OutlinedInput label="Role" />}
          renderValue={(selected) =>
            selected
              .map((value) =>
                String(translate(`global.teamRoles.${value.toLowerCase()}`))
              )
              .join(', ')
          }
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: 240 },
            },
          }}
        >
          {roleOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                disableRipple
                size="small"
                checked={filters.role.includes(option)}
              />
              {String(translate(`global.teamRoles.${option.toLowerCase()}`))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flexGrow={1}
        sx={{ width: 1 }}
      >
        <TextField
          fullWidth
          value={filters.search}
          onChange={handleFilterSearch}
          placeholder={String(translate('global.formLabels.search'))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
