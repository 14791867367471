import { Stack, Tooltip, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { FormProvider, RHFSwitch } from '#/components/shared/hook-form';
import { useChangeAdvisorDefaultSetting } from '#/api/partnerQueries';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type FormValues = {
  advisor_type: string;
  default: boolean;
};

type Props = {
  advisor_type: string;
  defaultValue?: boolean;
  refetch: () => void;
};

export default function DefaultListSwitch({
  advisor_type,
  defaultValue,
  refetch,
}: Props) {
  const { translate } = useLocales();

  const { mutateAsync: updateDefault } = useChangeAdvisorDefaultSetting();

  const defaultValues = useMemo(
    () => ({
      advisor_type,
      default: defaultValue ?? false,
    }),
    [advisor_type, defaultValue]
  );

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const { handleSubmit, watch, reset } = methods;

  const onSubmit = async (data: FormValues) => {
    await updateDefault(data).then(() => {
      refetch();
    });
  };

  const defaultSwitch = watch('default');

  useEffect(() => {
    if (defaultSwitch !== defaultValue) {
      handleSubmit(onSubmit)();
    }
  }, [defaultSwitch]);

  useEffect(() => {
    if (advisor_type || defaultValue) {
      reset(defaultValues);
    }
  }, [advisor_type, defaultValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 0.5 },
          width: {
            xs: '100%',
            sm: '25%',
          },
        }}
      >
        <RHFSwitch
          name="default"
          labelPlacement="start"
          label={
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Default
            </Typography>
          }
          sx={{ mx: 0 }}
        />
        <Tooltip
          title={String(
            translate('adminDashboard.consultantTable.defaultSwitchTooltip')
          )}
        >
          <Iconify
            icon="octicon:info-24"
            sx={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </Stack>
    </FormProvider>
  );
}
