import {
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import useTable from '#/hooks/useTable';
import TableSelectedAction from '#/components/shared/table/table-selected-action';
import Iconify from '#/components/shared/ui/Iconify';
import useBoolean from '#/hooks/useBoolean';
import Scrollbar from '#/components/shared/Scrollbar';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import { emptyRows } from '#/components/shared/table/utils';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import TablePaginationCustom from '#/components/shared/table/table-pagination-custom';
import LegalTableRow from '#/components/pages/Admin/consulting-legal-table/legal-table-row';
import LegalTableToolbar from '#/components/pages/Admin/consulting-legal-table/legal-table-toolbar';
import LegalTableFilterResult from '#/components/pages/Admin/consulting-legal-table/legal-table-filter-result';
import useLocales from '#/hooks/useLocales';
import AddConsultant from '#/components/pages/Admin/consulting-legal-table/AddConsultant/AddConsultant';
import { useGetAdvisorsList } from '#/api/partnerQueries';
import DefaultListSwitch from '#/components/pages/Admin/consulting-legal-table/DefaultSwitch/DefaultListSwitch';
import cityData from '#/components/pages/Admin/consulting-legal-table/AddConsultant/cityData';
import useDebounce from '#/hooks/useDebounce';
import sortingModel from '#/components/pages/Admin/consulting-legal-table/sortingModel';

const defaultFilters = {
  advisor_name: '',
  location: [],
  search: '',
};

const TABLE_HEAD = [
  { id: 'name', label: 'adminDashboard.consultantTable.tableHeads.name' },
  {
    id: 'email',
    label: 'adminDashboard.consultantTable.tableHeads.email',
    width: 300,
  },
  {
    id: 'location',
    label: 'adminDashboard.consultantTable.tableHeads.location',
    width: 250,
  },
  {
    id: 'added',
    label: 'adminDashboard.consultantTable.tableHeads.added',
    width: 150,
  },
  // { id: '', width: 88 },
];

export const locations = cityData.map((city) => city.label);

export default function ConsultingLegal() {
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedSearch = useDebounce(filters.search, 500);
  const table = useTable();

  const confirm = useBoolean();

  const hasFilters = filters?.location.length > 0 || debouncedSearch;

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const { data: advisors, refetch } = useGetAdvisorsList(
    hasFilters ? 1 : table.page + 1,
    'legal',
    true,
    filters?.location.length > 0
      ? // @ts-ignore
        filters?.location.map((element) => element.toLowerCase()).join(',')
      : undefined,
    debouncedSearch || undefined,
    table.order ? handleSorting() : undefined
  );

  const canReset = !isEqual(defaultFilters, filters);

  const notFound =
    (!advisors?.results?.length && canReset) || !advisors?.results?.length;

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const { translate } = useLocales();

  return (
    <Container maxWidth="lg">
      <Card>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            p: 2.5,
            pr: { xs: 2.5, md: 1 },
          }}
        >
          {String(translate('adminDashboard.legalAdvisors'))}
        </Typography>

        <DefaultListSwitch
          advisor_type="legal"
          defaultValue={advisors?.default}
          refetch={refetch}
        />
        <AddConsultant advisor_type="legal" />

        <LegalTableToolbar
          filters={filters}
          onFilters={handleFilters}
          locationsOptions={locations}
        />
        {canReset && (
          <LegalTableFilterResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={advisors?.count || 0}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={advisors?.count || 0}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                advisors?.results?.map((row) => String(row.id)) || []
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />
          <Scrollbar
            styledRootScrollbarSx={{
              height: advisors?.count === 0 ? '450px' : '100%',
            }}
          >
            <Table size="small" sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                onSort={table.onSort}
              />

              <TableBody>
                {advisors?.results?.map((row) => (
                  <LegalTableRow
                    key={row.id}
                    row={row}
                    selected={table.selected.includes(String(row.id))}
                    fromDefaultList={advisors.default}
                  />
                ))}

                <TableEmptyRows
                  height={56}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    advisors?.results?.length || 0
                  )}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={advisors?.count || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}
