import { useForm } from 'react-hook-form';
import { Card, Typography, Stack, MenuItem, IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import useAuth from '#/hooks/useAuth';
import {
  useAssignPartner,
  useDisconnectFromPartner,
  useGetPartnersList,
} from '#/api/partnerQueries';
import Iconify from '#/components/shared/ui/Iconify';
import DataSharingSetting from './data-sharing-setting';

export default function AccountDataSharing() {
  const [accessModal, setAccessModal] = useState(false);
  const [showList, setShowList] = useState(false);

  const handleShowList = () => setShowList(true);
  const handleOpenAccessModal = () => setAccessModal(true);
  const handleCloseAccessModal = () => setAccessModal(false);

  const { user, refetch } = useAuth();
  const { translate } = useLocales();
  const { data: partnersList } = useGetPartnersList();
  const { mutateAsync: assignPartner, isLoading: isSubmitting } =
    useAssignPartner();
  const { mutateAsync: disconnectPartner, isLoading: disconnecting } =
    useDisconnectFromPartner();

  const alreadySharing = user?.partner !== null;

  const defaultValues = useMemo(
    () => ({
      sharing: alreadySharing || false,
      partner_id: user?.partner?.id || '',
      has_specific_access: user?.has_specific_access ?? false,
      specific_service_access:
        user?.specific_service_access?.map((item: any) => item.service) ?? [],
    }),
    [user]
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, watch, reset } = methods;

  const disabled = isSubmitting;
  const wannaShare = watch('sharing') === true;

  const onSubmit = async (data: any) => {
    const body = {
      ...data,
      specific_service_access: data.specific_service_access.map(
        (item: string) => ({
          service: item,
          accessLevel: 'while-alive',
        })
      ),
      ...(!data.has_specific_access && {
        specific_service_access: [],
      }),
    };
    if (!wannaShare) {
      try {
        await disconnectPartner({});
        toast.success(
          String(translate('toast_notifications.success.disconnectFromPartner'))
        );
        refetch();
      } catch (e) {
        toast.error(
          String(translate('toast_notifications.error.disconnectFromPartner'))
        );
      }
    } else
      try {
        await assignPartner(body);
        toast.success(
          String(translate('toast_notifications.success.assignedNewPartner'))
        );
        refetch();
      } catch (error) {
        toast.error(
          String(translate('toast_notifications.error.assignedNewPartner'))
        );
      }
  };

  useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
  }, [user]);

  useEffect(() => {
    if (!user?.partner && wannaShare) {
      setShowList(true);
    }
  }, [user?.partner, wannaShare]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Typography variant="subtitle1">
          {user?.partner
            ? String(
                translate(
                  'global.userSettings.dataSharingPage.currentlySharing',
                  {
                    partnerName: user?.partner?.name,
                  }
                )
              )
            : String(
                translate('global.userSettings.dataSharingPage.noSharingTitle')
              )}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: 2,
          }}
        >
          <RHFSwitch
            name="sharing"
            label={String(
              translate('global.userSettings.dataSharingPage.toggleLabel')
            )}
          />
          {wannaShare && showList && (
            <RHFSelect
              name="partner_id"
              sx={{
                width: 200,
              }}
              label={String(
                translate(
                  'global.userSettings.dataSharingPage.partnerListLabel'
                )
              )}
            >
              {partnersList?.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.partner_name}
                </MenuItem>
              ))}
            </RHFSelect>
          )}
          {!showList && wannaShare && (
            <Typography
              variant="subtitle2"
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                '& .hover': {
                  color: 'red',
                },
              }}
              onClick={handleShowList}
            >
              {String(
                translate(
                  'global.userSettings.dataSharingPage.changedOrganisation'
                )
              )}
            </Typography>
          )}
        </Stack>
        {wannaShare && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mt: 2,
            }}
          >
            <RHFSwitch
              name="has_specific_access"
              label={String(translate('global.shareSelected'))}
            />
            <IconButton
              disabled={!watch('has_specific_access')}
              onClick={handleOpenAccessModal}
            >
              <Iconify
                icon="akar-icons:settings-horizontal"
                width={20}
                height={20}
                color={(theme) =>
                  watch('has_specific_access')
                    ? theme.palette.grey[900]
                    : theme.palette.action.disabled
                }
              />
            </IconButton>
          </Stack>
        )}
        <Stack
          sx={{ marginTop: 3 }}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ width: '70%' }}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {String(translate('global.userSettings.dataSharingPage.info'))}
            </Typography>
            <Typography variant="body1">
              {String(
                translate('global.userSettings.dataSharingPage.sharingName')
              )}
            </Typography>
            <Typography variant="body1">
              {String(
                translate('global.userSettings.dataSharingPage.sharingEmail')
              )}
            </Typography>
            <Typography variant="body1">
              {String(
                translate('global.userSettings.dataSharingPage.sharingLogin')
              )}
            </Typography>
            <Typography variant="body1">
              {String(
                translate(
                  'global.userSettings.dataSharingPage.sharingServiceProgress'
                )
              )}
            </Typography>
          </Stack>
          <LoadingButton
            aria-label="save changes"
            type="submit"
            variant="contained"
            loading={isSubmitting || disconnecting}
            disabled={disabled}
            sx={{ ml: 'auto', mt: 2 }}
          >
            <Typography>{String(translate('global.save'))}</Typography>
          </LoadingButton>
        </Stack>
      </Card>
      <DataSharingSetting
        open={accessModal}
        closeModal={handleCloseAccessModal}
      />
    </FormProvider>
  );
}
