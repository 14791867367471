import * as Yup from 'yup';
import { AccessRight, Successor } from '#/types/user';

export const SuccessorSchema = Yup.object().shape({
  invitations: Yup.array()
    .of(
      Yup.object().shape({
        unique_id: Yup.number().notRequired(),
        first_name: Yup.string().required('validations.successors.first_name'),
        last_name: Yup.string().required('validations.successors.last_name'),
        email: Yup.string()
          .email('validations.successors.invalidEmail')
          .required('validations.successors.email'),
        general_access_right: Yup.string().required(
          'validations.successors.general_access_right'
        ),
        type: Yup.string().notRequired(),
        has_specific_access: Yup.boolean(),
      })
    )
    .min(2, 'validations.successors.min'),
  agree: Yup.boolean().oneOf([true], 'validations.successors.agree'),
});

export const INITIAL_SPECIFIC_SERVICE_ACCESS = {
  service: '',
  accessLevel: 'after-death' as AccessRight,
};

export const INVITATION_FORM_INITIAL_VALUES: Successor = {
  first_name: '',
  last_name: '',
  email: '',
  type: 'SUCCESSOR',
  general_access_right: '' as AccessRight,
  has_specific_access: false,
  specific_service_access: [INITIAL_SPECIFIC_SERVICE_ACCESS],
};

export const INITIAL_INVITATIONS = Array(2).fill(
  INVITATION_FORM_INITIAL_VALUES
);

export const SUCESSOR_FORM_INITIAL_VALUES = {
  invitations: [INVITATION_FORM_INITIAL_VALUES],
  agree: false,
};

export const AccessRightOptions = [
  { value: 'after-death', label: 'global.afterDeath' },
  { value: 'while-alive', label: 'global.whileAlive' },
];

export const SERVICE_OPTIONS = [
  {
    value: 'livingWill',
    label: 'global.services.livingWill',
  },
  {
    value: 'powerOfAttorney',
    label: 'global.services.powerOfAttorney',
  },
  {
    value: 'lastWill',
    label: 'global.services.lastWill',
  },
  {
    value: 'marriageContract',
    label: 'global.services.marriageContract',
  },
  {
    value: 'registerOfAssets',
    label: 'global.services.registerAssets',
  },
  {
    value: 'digitalFootprint',
    label: 'global.services.digitalFootprint',
  },
  {
    value: 'cohabitationAgreement',
    label: 'global.services.cohabitationAgreement',
  },
];
