import { useForm } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import {
  FormProvider,
  RHFTextField,
  RHFUploadBasic,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { invalidatePartnerQueries, useAddAdvisor } from '#/api/partnerQueries';
import cityData from '#/components/pages/Admin/consulting-legal-table/AddConsultant/cityData';
import RHFAutocomplete from '#/components/shared/hook-form/RHFAutocomplete';

interface IFormInput {
  advisor_name: string;
  location: string;
  logo: string;
  email: string;
  advisor_type: string;
}

type Props = {
  advisor_type: string;
};

export default function AddConsultant({ advisor_type }: Props) {
  const { translate } = useLocales();

  const { mutateAsync: addAdvisor, isLoading } = useAddAdvisor();

  const defaultValues = {
    advisor_name: '',
    location: '',
    logo: '',
    email: '',
    advisor_type,
  };

  const methods = useForm<IFormInput>({
    defaultValues,
  });
  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = async (data: IFormInput) => {
    const formData = new FormData();

    formData.append('logo', data.logo);
    formData.append('email', data.email);
    formData.append('advisor_name', data.advisor_name);
    formData.append('advisor_type', data.advisor_type);
    formData.append('location', data.location);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await addAdvisor(formData)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.addAdvisor'))
        );
        reset(defaultValues);
        invalidatePartnerQueries.advisorsList();
      })
      .catch(() => {
        toast.error(String(translate('toast_notifications.error.addAdvisor')));
      });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // @ts-ignore
        setValue('logo', newFile, { shouldValidate: true });
      }
    },
    // eslint-disable-next-line
    [setValue]
  );

  const handleRemove = useCallback(() => {
    setValue('logo', '', { shouldValidate: true });
  }, [setValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{
            p: 2.5,
          }}
        >
          <RHFUploadBasic
            name="logo"
            placeholderHeading={String(
              translate('global.formLabels.uploadLogo')
            )}
            onDrop={handleDrop}
            onDelete={handleRemove}
          />
          <RHFTextField
            name="email"
            label={String(translate('global.formLabels.emailAddress'))}
            size="small"
          />
          <RHFTextField
            name="advisor_name"
            label={String(translate('global.formLabels.name'))}
            size="small"
          />
          {/* <RHFSelect */}
          {/*  name="location" */}
          {/*  label={String(translate('global.formLabels.location'))} */}
          {/*  size="small" */}
          {/* > */}
          {/*  {cityData.map((city, i) => ( */}
          {/*    <MenuItem key={`${city.value}-${i}`} value={city.value}> */}
          {/*      {city.label} */}
          {/*    </MenuItem> */}
          {/*  ))} */}
          {/* </RHFSelect> */}
          <RHFAutocomplete
            name="location"
            options={cityData}
            label={String(translate('global.formLabels.location'))}
            size="small"
          />

          <Stack>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              <Typography>
                {String(translate('global.formLabels.invite'))}
              </Typography>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}
