import React, { useCallback } from 'react';
import {
  FormControl,
  // SelectChangeEvent,
  // InputLabel,
  // OutlinedInput,
  // MenuItem,
  // Checkbox,
  Stack,
  // Select,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = {
  filters: {
    advisor_name: string;
    location: string[];
    search: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  locationsOptions: string[];
};

export default function LegalTableToolbar({
  filters,
  onFilters,
  locationsOptions,
}: Props) {
  const { translate } = useLocales();

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search', event.target.value);
    },
    [onFilters]
  );

  // const handleFilterLocation = useCallback(
  //   (event: SelectChangeEvent<string[]>) => {
  //     onFilters(
  //       'location',
  //       typeof event.target.value === 'string'
  //         ? event.target.value.split(',')
  //         : event.target.value
  //     );
  //   },
  //   [onFilters]
  // );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        {/* <Select */}
        {/*  multiple */}
        {/*  value={filters.location} */}
        {/*  onChange={handleFilterLocation} */}
        {/*  input={<OutlinedInput label="Location" />} */}
        {/*  renderValue={(selected) => selected.map((value) => value).join(', ')} */}
        {/*  MenuProps={{ */}
        {/*    PaperProps: { */}
        {/*      sx: { maxHeight: 240 }, */}
        {/*    }, */}
        {/*  }} */}
        {/* > */}
        {/*  {locationsOptions.map((option) => ( */}
        {/*    <MenuItem key={option} value={option}> */}
        {/*      <Checkbox */}
        {/*        disableRipple */}
        {/*        size="small" */}
        {/*        checked={filters.location.includes(option)} */}
        {/*      /> */}
        {/*      {option} */}
        {/*    </MenuItem> */}
        {/*  ))} */}
        {/* </Select> */}

        <Autocomplete
          multiple
          id="location"
          value={filters.location}
          onChange={(_event, newValue) => {
            onFilters('location', newValue);
          }}
          options={locationsOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={String(translate('global.formLabels.location'))}
            />
          )}
        />
      </FormControl>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flexGrow={1}
        sx={{ width: 1 }}
      >
        <TextField
          fullWidth
          value={filters.search}
          onChange={handleFilterSearch}
          placeholder={String(translate('global.formLabels.search'))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
