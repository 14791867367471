import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = StackProps & {
  filters: {
    advisor_name: string;
    location: string[];
    search: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  onResetFilters: VoidFunction;
  results: number;
};

export default function LegalTableFilterResult({
  filters,
  onFilters,
  onResetFilters,
  results,
  ...other
}: Props) {
  const { translate } = useLocales();

  const handleRemoveKeyword = useCallback(() => {
    onFilters('search', '');
  }, [onFilters]);

  const handleRemoveLocation = useCallback(
    (inputValue: string) => {
      const newValue = filters.location.filter((item) => item !== inputValue);
      onFilters('location', newValue);
    },
    [filters.location, onFilters]
  );

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {/* {filters.status !== 'all' && ( */}
        {/*  <Block label="Status:"> */}
        {/*    <Chip */}
        {/*      size="small" */}
        {/*      label={filters.status} */}
        {/*      onDelete={handleRemoveStatus} */}
        {/*    /> */}
        {/*  </Block> */}
        {/* )} */}

        {!!filters.location.length && (
          <Block label="Location:">
            {filters.location.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveLocation(item)}
              />
            ))}
          </Block>
        )}

        {!!filters.search && (
          <Block label="Keyword:">
            <Chip
              label={filters.search}
              size="small"
              onDelete={handleRemoveKeyword}
            />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          <Typography>{String(translate('global.removeAll'))}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
}

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
