const reminderFrequencies = [
  {
    value: 'weekly',
  },
  {
    value: 'monthly',
  },
  {
    value: 'quarterly',
  },
  {
    value: 'disabled',
  },
];

export default reminderFrequencies;
